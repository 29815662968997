import './App.css';

import Wrapper from './components/Wrapper'
import Header from './components/Header'
import Container from 'react-bootstrap/Container';




function App() {
  return (
    <Container>
      <link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
  integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
  crossorigin="anonymous"
/>
      <Header/>
      <Wrapper/>
    </Container>
    
    
  );
}

export default App;
