import {useState, useEffect} from 'react'
import GridCards from './GridCards';
import SearchBox from './SearchBox';


function Wrapper(){

    const [results,setResults] = useState([]);
    const [searchText, setSearchText] = useState('');

    function onSearchTextChangeHandler(event){
        setSearchText(event.target.value);
    }

    function find(cards, text) {
        return cards.filter(card => { 
            const allText = card.title + card.summary; 
             
            return allText.toLowerCase().includes(text.toLowerCase());         
        });
      }
    

    useEffect( ()=> {
        async function fred(){
            const res = await fetch("https://iylkg1dxsi.execute-api.eu-west-1.amazonaws.com/prod/sites",);
            const res2 = await res.json();            
            setResults(res2.cards);
        }
        fred();        
    },[]);

    return (
        <>
        <SearchBox onSearchTextChange={onSearchTextChangeHandler}/>
        <GridCards cards={find(results, searchText)}/>
        </>
    )
}

export default Wrapper;
