import Card from 'react-bootstrap/Card';

function Header() {
  return (
    <Card className="text-center">
      <Card.Header>Tech Stuff</Card.Header>
      <Card.Body>
        <Card.Title>Martin White Dev</Card.Title>
        <Card.Text>
          With supporting text below as a natural lead-in to additional content.
        </Card.Text>        
      </Card.Body>      
    </Card>
  );
}

export default Header;