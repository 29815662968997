import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

function SearchBox({onSearchTextChange}) {
  return (
    <>
      <FloatingLabel
        controlId="floatingTextarea"
        label="Search text"
        className="mb-3"
      >
        <Form.Control as="textarea"  onChange={onSearchTextChange}
        placeholder="Leave a comment here" />
      </FloatingLabel>
     
    </>
  );
}

export default SearchBox;